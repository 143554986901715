export const reducer = ( state, action ) => {

    switch (action.type) {
        case 'UpdateData':
            return {
                ...state,
                data: action.payload,
            }
            break;
        case 'UpdateOpenModal':
            return {
                ...state,
                openModal: action.payload,
            }
            break;
        case 'UpdateSolicitud':
            return {
                ...state,
                solicitud: action.payload,
            }
            break;
        case 'UpdateColegio':
                return {
                    ...state,
                    colegio: action.payload,
                }
                break;
        default:
            return state;
    }
}