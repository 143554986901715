import http from "./httpService";
import { apiURL } from "../config.json";
import {setJwt, getJwt, getDataJwt} from "./httpService";

export function getData() {
  let { matricula } = getDataJwt();
  let apiEndPoint = apiURL + `/SIAF_AP/${ matricula }`;
  
  setJwt( getJwt() );
  return http.get( apiEndPoint );
}

export function getNuevaMatricula() {
  let { matricula } = getDataJwt();
  let apiEndPoint = apiURL + `/SIAF_AP/nuevaMatricula/${ matricula }`;
  
  setJwt( getJwt() );
  return http.get( apiEndPoint );
}

export function getSolicitarMatricula() {
  const { matricula } = getDataJwt();
  const apiEndPoint = apiURL + `/SIAF_AP/SolicitarMatricula`;
  
  setJwt( getJwt() );
  return http.post( apiEndPoint, matricula );
}
export function postPagarMatricula( formData ) {
  const apiEndPoint = apiURL + `/SIAF_AP`;
  const { matricula } = getDataJwt();

  formData.append('matricula',  parseInt(matricula));

  const config = { headers : { 'content-type':'miltipart/form-data'}} 

  setJwt( getJwt() );
  return http.post( apiEndPoint, formData, config );
}