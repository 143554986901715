import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Context } from '../context/Context';

const useStyles = makeStyles({ 
        root: { flexGrow: 1 }, 
        grid: { fontWeight: 'bold', 
                textAlign:'right',
                marginBottom: 10,
                paddingRight: 10 },
        gridTitle: { fontWeight: 'bold', 
                     textAlign:'center',
                     textTransform: 'uppercase',
                     marginBottom: 20,
                     color: 'blue' }
    });

export default function Personales() {
    const { state : { data } } = useContext(Context);
    const { alumno, matricula } = data;

    const classes = useStyles();

    return (
    <Paper square className={classes.root}>
        <Grid container component="main" spacing={3}>
            <Grid container xs={12} md={6}>
                <Grid item xs={12} className={classes.gridTitle} >
                    Personal 
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Código: 
                </Grid>
                <Grid item xs={6} >
                    {alumno.codigo}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Sexo: 
                </Grid>
                <Grid item xs={6} >
                    {alumno.sexo}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Edad: 
                </Grid>
                <Grid item xs={6} >
                    {alumno.edad}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Fecha Nacimiento: 
                </Grid>
                <Grid item xs={6} >
                    {alumno.fechaNacimiento}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Lugar Nacimiento: 
                </Grid>
                <Grid item xs={6} >
                    {alumno.lugarNacimiento}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Nacionalidad: 
                </Grid>
                <Grid item xs={6} >
                    {alumno.nacionalidad}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Dirección: 
                </Grid>
                <Grid item xs={6} >
                    {alumno.direccion}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Telefono/Celular: 
                </Grid>
                <Grid item xs={6} >
                    {alumno.telefono} / {alumno.celular}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    E-mail: 
                </Grid>
                <Grid item xs={6} >
                    {alumno.email}
                </Grid>
            </Grid>
            <Grid container xs={12} md={6}>
                <Grid item xs={12} className={classes.gridTitle} >
                    Academica
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Año Lectivo: 
                </Grid>
                <Grid item xs={6} >
                    {matricula.añoLectivo}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Centro Procedencia: 
                </Grid>
                <Grid item xs={6} >
                    {matricula.centroEscolar}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Fecha Matricula: 
                </Grid>
                <Grid item xs={6} >
                    {matricula.fechaMatricula}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Tipo Ingreso: 
                </Grid>
                <Grid item xs={6} >
                    {matricula.tipoIngreso}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Tipo Matricula: 
                </Grid>
                <Grid item xs={6} >
                    {matricula.tipoMatricula}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Curso: 
                </Grid>
                <Grid item xs={6} >
                    {matricula.curso}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Docente Guia: 
                </Grid>
                <Grid item xs={6} >
                    {matricula.docenteGuia}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Conducta: 
                </Grid>
                <Grid item xs={6} >
                    {matricula.conducta}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Carta Compromiso: 
                </Grid>
                <Grid item xs={6} >
                    {matricula.cartaCompromiso ? 'Si' : 'No'}
                </Grid>
                <Grid item xs={6} className={classes.grid} >
                    Es Repitente: 
                </Grid>
                <Grid item xs={6} >
                    {matricula.esRepitente ? 'Si' : 'No'}
                </Grid>
            </Grid>
        </Grid>
    </Paper>
    );
}