import React, { useState, useContext, useRef } from 'react'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Publish from  '@material-ui/icons/Publish';

import ConfirmDialog from './ConfirmDialog';
import { postPagarMatricula } from '../servicios/calificacionesService';
import { Context } from '../context/Context';

const useStyles = makeStyles((theme) => ({
    contenedor: {
        flexGrow: 1,
        flexDirection: 'column',
    },
    contenedorSub: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'flex-start',
        margin: 10,
    },
    titulo: {
        textAlign: 'center',
        fontSize: 17,
        fontWeight:'bold',
        textTransform: 'uppercase',
        textShadow: '2px 2px 5px grey',
    },
    elementoLF: {
        width:'30%',
        textAlign:'right',
        marginRight: 10,
        padding: 5,
    },
    elementoRG: {
        fontWeight:'bold',
        backgroundColor:'#F2F3F4',
        padding: 5,
    },
    file: {
        width: 0,
        height: 0
    },
    button: {
        marginTop: 10,
        width: '70%',
        fontWeight: 'bold',
        padding: 6,
        borderRadius: 15,
        marginBottom: 20,
    },
    buttonAplicar: {
        marginTop: 10,
        width: '70%',
        fontWeight: 'bold',
        padding: 6,
        borderRadius: 15,
        marginBottom: 20,
    },
    notas:{
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontStyle: 'italic',
    }
}));

export const SolicitarPagarMatricula = () => {
    const classes = useStyles();
    const { state : { data : { alumno }, solicitud }, updateOpenModal } = useContext(Context)
    console.log(solicitud)
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [imagen, setImagen] = useState({ filepreview: null });
    const [fileImagen, setFileImagen] = useState( null );
    const fileInput = useRef(null)

    const PagarMatricula = async () => {
        var datos = new FormData();

        datos.append('id', 0);
        datos.append('file',  fileImagen);

        const result = await postPagarMatricula( datos );

        if (result.status === 200)
            updateOpenModal(false)
    }
    const onChange = (e) => {
        if ( e.target.files.length > 0 ) {
            setImagen( {filepreview:URL.createObjectURL(e.target.files[0])} )
            setFileImagen(e.target.files[0])
        }
    } 

    const CargarFile = () => {
        fileInput.current.click()
    }

    return (
        <>
            <div className= {classes.contenedor}>
                <div className= {classes.contenedorSub}>
                    <div className={classes.elementoLF}>
                        Alumno
                    </div>
                    <div className={classes.elementoRG}>
                        {alumno.alumno}
                    </div>
                </div>
                <div className= {classes.contenedorSub}>
                    <div className={classes.elementoLF}>
                    Año Lectivo/Curso
                    </div>
                    <div className={classes.elementoRG}>
                    {solicitud.añoLectivo}/{solicitud.curso}
                    </div>                             
                </div>
                <div className= {classes.contenedorSub}>
                    <div className={classes.elementoLF}>
                        Datos Cuenta
                    </div>
                    <div className={`${classes.notas} ${classes.elementoRG}`}>
                        {solicitud.datosCta}
                    </div>
                </div>
                <div className= {classes.contenedorSub}>
                    <div className={classes.elementoLF}>
                        Costo Matricula
                    </div>
                    <div className={`${classes.notas} ${classes.elementoRG}`}>
                        {solicitud.moneda} {solicitud.montoMatricula} (Reserva cupo con $ 10)
                    </div>
                </div>
                {solicitud.ruta === "" &&
                <div className= {classes.contenedorSub}>
                    En este paso debe realizar el pago de la matricula via deposito bancario o transferencia electronica, una vez realice el pago debe subir una captura del comprobante para su verificacion.<br/><br/>
                    Para ello de clic en CARGAR ARCHIVO y indique donde esta ubicada la captura del comprobante.
                </div>
                }
                <div className= {classes.contenedorSub}
                     style={{justifyContent:'center'}}>
                    { solicitud.ruta === "" && 
                        <div>
                            <Button 
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                size = "large"
                                onClick={ CargarFile } 
                                startIcon={<Publish />}>
                                Cargar Archivo
                            </Button>
                            <input type="file" 
                                name="myImage" 
                                onChange= {onChange} 
                                className={classes.file}
                                ref={fileInput}/>
                        </div>
                    }
                    <img src={solicitud.ruta !== "" ? solicitud.ruta : imagen.filepreview} height="300" alt=""/>
                </div>
                <div style={{textAlign:'center'}}>
                    { solicitud.registrado  
                        ?   <Typography variant="h6"
                                        style={{marginTop: 10, backgroundColor: 'grey', color:'white' }}>
                                La solictud de matricula esta pendiente de aprobacion.
                            </Typography>
                        :   <Button 
                                className={classes.buttonAplicar}
                                variant="contained"
                                color="primary"
                                size = "large"
                                onClick={ () => setConfirmOpen(true)}
                                disabled = { !(imagen.filepreview)} >
                                Solicitar Matricula
                            </Button>
                    }   
                </div>
                <div className= {classes.contenedorSub}>
                    * Su solictud de matricula esta sujeta a aprobacion por la administracion.
                </div>
            </div>

            <ConfirmDialog title = {`¿Confirma Solicitar Matricula?`} 
                            open = { confirmOpen } 
                            setOpen = { setConfirmOpen } 
                            onConfirm = { PagarMatricula }/>
        </>
    )
}
