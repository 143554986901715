import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { Context } from '../context/Context';
import logo from "../avatar.jpg";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex',
          '& > *': {
          margin: theme.spacing(1),
        },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  grid : {
    marginBottom: 5,
    [theme.breakpoints.up('md')]: {
      marginBottom: 10,
    },
  },
  avatar: {
    display: 'flex',
    justifyContent:'center',
  },
  boton: {
    marginTop: 0,
    color: 'yellow',
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
      color: 'white',
    },
  }
}));

export default function Encabezado() {
  const classes = useStyles();
  
  const { state : { data, openModal }, updateOpenModal } = useContext(Context)
  return (
    <div className={classes.root}>
      { data.alumno  &&
      <Grid container component="main" spacing={1}>
        <Grid item xs={12} md={1}  className={classes.avatar}  >
          { !data.alumno.foto
            ? <Avatar alt="Remy Sharp" src={logo} className={classes.large} />
            : <Avatar alt="Remy Sharp" src={`data:text/plain;base64,${data.alumno.foto}`} className={classes.large} /> }
        </Grid>
        <Grid item xs={12} md={9}  className={classes.grid} >
          <Grid item xs={12}  className={classes.grid} >
            <Typography variant="h5">{ data.alumno.alumno }</Typography> 
          </Grid>
          <Grid item xs={12}  className={classes.grid} >
            <Typography variant="h5">{ data.matricula.curso }</Typography> 
          </Grid>
        </Grid>
        
      </Grid>
      }
    </div>
  );
}
/**
 * <Grid item xs={12} md={2} 
              style={{textAlign: 'center'}} className={classes.grid}>
          { data.alumno.enListaNegra === false &&
          <Button variant="contained" 
                  className={classes.boton}
                  color="primary"
                  onClick={ () => updateOpenModal(!openModal)}>
              Matriculate Aqui
          </Button>
          } 
        </Grid>
 * 
 */