import React, {useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

import Personales from './Personales';
import Calificaciones from './Calificaciones';
import { Anuncios } from './Anuncio'
import { getData } from '../servicios/calificacionesService';
import { Context } from '../context/Context';

const useStyles = makeStyles({ root: { flexGrow: 1 } });

export default function Alumno() {
  const classes = useStyles();

  const { updateData, updateColegio } = useContext(Context)

  const [cargado, setCargado] = useState(false);
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect( () => {
    (async () => {
      if ( !cargado ){
        const result = await getData();
        console.log(result.data)
        updateData(result.data);
        updateColegio(result.data.infoInstitucional.colegio)
        setCargado(true);
      }
    })()
  }, []);

  
  return (
    <Paper square className={classes.root}>
      { !cargado && <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box> }

      <TabContext value={value}>
        <Tabs value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              aria-label="icon tabs example">
          <Tab label="Anuncios" value="1" />
          <Tab label="Información" value="2"/>
          <Tab label="Calificaciones" value="4"/>
         
        </Tabs>
        <TabPanel value="1">
          <Anuncios />
        </TabPanel>
        <TabPanel value="2">
          { cargado && <Personales/> }
        </TabPanel>
        <TabPanel value="4">
          { cargado && <Calificaciones/> }
        </TabPanel>
      </TabContext>
    </Paper>
  );
}
/*
<Tab label="Calificaciones Detalle" value="5"/>
<TabPanel value="5">Item One5</TabPanel>

anuncios = {data.anuncios}
data= { {alumno : data.alumno, matricula : data.matricula} }


                         cortes = {data.cortes}
                         tipos = {data.tipoDisciplinas}
                         disciplinas = {data.disciplinas}
                         conceptos = {data.conceptos}
                         notas = {data.notas}
                         notasCON = {data.notasCON}
                         totales = {data.totales}
                         EI = {data.matricula.ei}
*/