import React, { createContext, useReducer } from 'react'
import { reducer } from './Reducer'
export const initialState = { colegio: "",
                              data: { hits: [] },
                              solicitud: {}  ,
                              openModal: false, }

export const Context = createContext( {} );

export const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const updateData = (data) => dispatch({ type: 'UpdateData', payload : data });
    const updateSolicitud = (data) => dispatch({ type: 'UpdateSolicitud', payload : data });
    const updateOpenModal = (data) => dispatch({ type: 'UpdateOpenModal', payload : data });
    const updateColegio = (data) => dispatch({ type: 'UpdateColegio', payload : data });

    return (
        <Context.Provider value = {{
            state,
            updateData,
            updateSolicitud,
            updateOpenModal,
            updateColegio,
        }}>
            { children }
        </Context.Provider>
    )
}
