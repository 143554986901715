import React, { useContext } from 'react';
import { makeStyles  } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import auth from '../servicios/JWTAuth';
import Encabezado from "./Encabezado";
import Alumno from "./Alumno";
import {NuevaMatricula} from './NuevaMatricula';
import { Context } from '../context/Context';
import './css/master.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1),
        fontSize: '0.9em'
    },
    title: {
        flexGrow: 1,
        fontStyle: 'italic',
        marginRight: theme.spacing(5),
    },
    contenedorMenu: {
        flexGrow: 1
    }
}));

export default function Master( { updateEstado }) {
    const classes = useStyles();

    const { state : { openModal, colegio } } = useContext(Context)
    const [anchorEl, setAnchorEl] = React.useState(false);
    const handleMenu = ( event ) => { setAnchorEl(event.currentTarget); };

    const handleClose = () => { setAnchorEl(null); };
    
    const CerrarSesion = () => { 
        auth.logout();
        updateEstado();
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        <span className='subTitle'>
                            "{colegio}"
                        </span>
                    </Typography>
                    <Tooltip title="Cerrar Sesion">
                       <IconButton edge = "start" 
                                   color = "inherit" 
                                   className = {classes.menuButton} 
                                   aria-label = "menu"
                                   onClick = {handleMenu}>
                            <AccountCircleIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu id="menu-appbar"
                          keepMounted
                          open={anchorEl}
                          anchorEl={anchorEl}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                          transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                          onClose={handleClose}>
                        <MenuItem onClick={CerrarSesion}>Cerrar Sesión</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
           
            <main className="container">
                <Encabezado/>
                <Alumno/>
                
                { openModal && <NuevaMatricula/> }

                <ToastContainer />
            </main> 
        </div>
    );
}
/*<MenuItem>Cambiar Contraseña</MenuItem> */