import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Login } from "./componentes/Login";
import Master from "./componentes/Master";
import { Provider } from './context/Context';

export const App = () => {
    const [ autenticado, setAutenticado] = useState(localStorage.getItem("access_token"))
    const update = () => (setAutenticado( localStorage.getItem("access_token") ))
        
    return ( <AppState>
                 { autenticado 
                 ? <Router> 
                        <Master updateEstado={update}/>
                   </Router>
                 : <Login updateEstado={update}/>}                
             </AppState> );
}

const AppState = ({ children }) => {
  return (
    <Provider>
      { children }
    </Provider>
  )
}