
import React, { useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Context } from '../context/Context';

export const Anuncios = () => {
    const { state : { data } } = useContext(Context);
    const { anuncios } = data;
    
    return <>
    { anuncios && anuncios.map( anuncio => {
        return  <Alert severity={ anuncio.prioridad === 1 ? "warning" : "info" } 
                       key={Math.random()}
                       style={{marginBottom: 10}}>
                    <AlertTitle>{anuncio.titulo}</AlertTitle>
                    {anuncio.detalle}
                </Alert>
      })
    }
    </>
}