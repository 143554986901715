import React, { useState, useContext} from 'react'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ConfirmDialog from './ConfirmDialog';
import { getSolicitarMatricula } from '../servicios/calificacionesService';
import { Context } from '../context/Context';

const useStyles = makeStyles((theme) => ({
    contenedor: {
        flexGrow: 1,
        flexDirection: 'column',
    },
    contenedorSub: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'flex-start',
        margin: 10,
    },
    titulo: {
        textAlign: 'center',
        fontSize: 17,
        fontWeight:'bold',
        textTransform: 'uppercase',
        textShadow: '2px 2px 5px grey',
    },
    elementoLF: {
        width:'30%',
        textAlign:'right',
        marginRight: 10,
        padding: 5,
    },
    elementoRG: {
        fontWeight:'bold',
        backgroundColor:'#F2F3F4',
        padding: 5,
    },
    button: {
        marginTop: 10,
        width: '70%',
        fontWeight: 'bold',
        padding: 6,
        borderRadius: 15,
        marginBottom: 20,
    }
}));
export const SolicitudMatricula = () => {
    const { state : { data : { alumno, matricula }, solicitud }, updateOpenModal } = useContext(Context)
    const classes = useStyles();

    const [confirmOpen, setConfirmOpen] = useState(false);

    const SolicitarMatricula = async () => {
        const result = await getSolicitarMatricula();

        if (result.status === 200)
            updateOpenModal(false)
    }
    return (
        <>
            <div className= {classes.contenedor}>
                        <div className={classes.titulo}>
                            Matricula Activa 
                        </div>
                        <div className= {classes.contenedorSub}>
                            <div className={classes.elementoLF}>
                                Año Lectivo
                            </div>
                            <div className={classes.elementoRG}>
                                {matricula.añoLectivo}
                            </div>
                        </div>
                        <div className= {classes.contenedorSub}>
                            <div className={classes.elementoLF}>
                                Alumno
                            </div>
                            <div className={classes.elementoRG}>
                                {alumno.alumno}
                            </div>
                        </div>
                        <div className= {classes.contenedorSub}>
                            <div className={classes.elementoLF}>
                                Curso
                            </div>
                            <div className={classes.elementoRG}>
                                {matricula.curso}
                            </div>                             
                        </div>

                        <div className={classes.titulo}>
                            Nueva Matricula 
                        </div>
                        <div className= {classes.contenedorSub}>
                            <div className={classes.elementoLF}>
                                Año Lectivo
                            </div>
                            <div className={classes.elementoRG}>
                                {solicitud.añoLectivo}
                            </div>
                        </div>
                        <div className= {classes.contenedorSub}>
                            <div className={classes.elementoLF}>
                                Curso
                            </div>
                            <div className={classes.elementoRG}>
                                {solicitud.curso}
                            </div>                             
                        </div>
                        <div className= {classes.contenedorSub}>
                            <div className={classes.elementoLF}>
                                Fecha/Matricula
                            </div>
                            <div className={classes.elementoRG}>
                                {new Date().toLocaleDateString("es-NI")}
                            </div>
                        </div>
                        <div style={{textAlign:'center'}}>
                            { solicitud.registrado 
                                ?   <Typography variant="h6"
                                                style={{marginTop: 10, backgroundColor: 'grey', color:'white' }}>
                                        Su solicitud de Matricula esta en procesos
                                    </Typography>
                                :   <Button 
                                        className={classes.button}
                                        variant="contained"
                                        color="primary"
                                        size = "large"
                                        onClick={ () => setConfirmOpen(true)} >
                                        Solictar Matricula
                                    </Button>
                            }   
                        </div>
                    </div>

                    <ConfirmDialog title = {`¿Confirma Solicitar la Matricula?`} 
                                   open = { confirmOpen } 
                                   setOpen = { setConfirmOpen } 
                                   onConfirm = { SolicitarMatricula }/>
        </>
    )
}
