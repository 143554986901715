import React, { useEffect, useState, useContext } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';

import { getNuevaMatricula } from '../servicios/calificacionesService';
import { SolicitarPagarMatricula } from './SolicitarPagarMatricula';
import { SolicitudMatricula } from './SolicitudMatricula';
import { PagarMatricula } from './PagarMatricula';
import { Context } from '../context/Context';
const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const steps = [
    'Solicitar',
    'Pagar',
    'Finalizar',
  ];

export const NuevaMatricula = () => {
    const classes = useStyles();
    const { state : { data, solicitud, openModal }, updateSolicitud, updateOpenModal } = useContext(Context)
    
    useEffect(() => {
        (async () => {
            const result = await getNuevaMatricula();
            
            if (result.status === 200)
                updateSolicitud(result.data);
        })()    
    }, [])

    return (
            <Dialog onClose = { () => updateOpenModal(false) } 
                disableEscapeKeyDown
                aria-labelledby = "customized-dialog-title" 
                open = { openModal }
                fullWidth = { true }
                maxWidth = 'md'>
                <DialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">
                        Solictud de Matricula Reingreso
                    </Typography>
                    <IconButton aria-label="close" 
                                className={classes.closeButton}
                                onClick={() => updateOpenModal(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <SolicitarPagarMatricula/>
                </DialogContent>
            </Dialog>
    )
}
/*
<Box sx={{ width: '100%' }}>
                        <Stepper activeStep={solicitud.estado}>
                            {steps.map((label, i) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                    { solicitud &&
                        solicitud.estado === 0 
                        ? <SolicitudMatricula/>
                        : solicitud.estado === 1 || solicitud.estado === 2 
                            ? <PagarMatricula/> 
                            : null
                    }


*/