import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

import auth from '../servicios/JWTAuth';
import { getDataJwt } from '../servicios/httpService';
import './css/login.css';

export const Login = ({updateEstado}) => {
    const [habilitado, setHabilitado] = useState(false)
    const [error, setError] = useState(false)
    const [pinAcceso, setPinAcceso] = useState("")
    const [mensajeError, setMensajeError] = useState("")
    const [cargando, setCargando] = useState(false)

    const ValidarInput = ({ currentTarget: input }) => {
        const habilitad = !( input.value === "" );
        const data = input.value;
        
        setHabilitado(habilitad)
        setPinAcceso(data);
    };

    const VerificarAcceso = async () => {
        try {
            setCargando(true)
            const error = await auth.login(pinAcceso);

            if ( error === 400) {
                setMensajeError("Error de Inicio de sesion, Pin Acceso invalidos.");
            } else {
                const { solvente } = getDataJwt();

                if ( parseInt( solvente ) === 1 ) {
                    updateEstado();
                    return;
                } else {
                    setMensajeError("Su cuenta esta en MORA, debe estar solventa para tener acceso a la plataforma.");
                }
            }
            setHabilitado(false)
            setError(true)
            setPinAcceso("")
            setCargando(false)
            setTimeout(function() { 
                setMensajeError("");
                setError(false)
            }, 2000);

        } catch (error) {}
    };

    return (
        <div className='root'>
            <div className='image'/>
            <div className='paper'>
                <div className='titulo'>
                    SISTEMA DE INFORMACION ACADEMICA
                </div>
                <div className='subTitulo'>
                    <h2>Inicio Sesion</h2>
                </div>
                <div className='margenSuperior'>
                    <TextField  variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="pinAcceso"
                                label="Pin Acceso"
                                name="pinAcceso"
                                value={ pinAcceso }
                                onChange={ e => ValidarInput(e) }
                                autoFocus/>
                </div>
                
                <div className='margenSuperior'>
                    { error && <Typography color = 'error'>
                                            { mensajeError }
                                        </Typography>
                    }
                </div>
                <div className='margenSuperior'>
                    <Button type="button"
                            fullWidth
                            disabled={!habilitado}
                            variant="contained"
                            color="primary"
                            size = "large" 
                            onClick = {VerificarAcceso} >
                        Ingresar al Sistema
                    </Button>
                </div>
                <div className='margenSuperior'>
                { cargando && <Box sx={{ width: '100%' }}>
                                  <LinearProgress />
                              </Box> }
                </div>
            </div>
        </div>
        );
    };